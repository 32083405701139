import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Podcast from "../../components/podcast/Podcast"
import SEO from "../../components/SEO"

const DrMikeKirlewPresents = ({ data }) => {
  const { drMikeKirlew, drMikeKirlewMeta, kirlewCover, kirlewContent } = data
  const fluid = kirlewCover.childImageSharp.fluid

  return (
    <Layout>
      <SEO title={drMikeKirlewMeta.title} />
      <Podcast
        metaData={drMikeKirlewMeta}
        title={drMikeKirlewMeta.title}
        episodes={drMikeKirlew.edges}
        fluid={fluid}
        description={kirlewContent._rawKirlewDescription}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    drMikeKirlew: allFeedDrMikeKirlew {
      edges {
        node {
          id
          title
          link
          pubDate
          isoDate(formatString: "MMM D, YYYY")
          content {
            encoded
          }
          enclosure {
            url
            type
          }
          itunes {
            duration
            subtitle
          }
        }
      }
    }
    drMikeKirlewMeta: feedDrMikeKirlewMeta {
      id
      title
      pubDate
      image {
        url
      }
      docs
      feedUrl
      description
      link
    }
    kirlewCover: file(relativePath: { eq: "Kirlew1400.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kirlewContent: sanityPodcastPage {
      _rawKirlewDescription(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default DrMikeKirlewPresents
